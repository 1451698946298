/*Fuentes*/
@font-face {
    font-family: "StyreneA-Bold";
    src: local("StyreneA-Bold"),
        url("./fonts/StyreneA-Bold.otf") format("opentype");
}

@font-face {
    font-family: "StyreneA-Regular";
    src: local("StyreneA-Regular"), url("./fonts/StyreneA-Regular.otf") format("opentype");
}

@font-face {
    font-family: "StyreneA-Black";
    src: local("StyreneA-Black"), url("./fonts/StyreneA-Black.otf") format("opentype");
}

@font-face {
    font-family: "StyreneA-Light";
    src: local("StyreneA-Light"), url("./fonts/StyreneA-Light.otf") format("opentype");
}

body {
    font-family: "StyreneA-Light";
}

/*Proximamente*/
.fondoProximamente {
    background-image: url("https://eenchulame.s3.us-west-2.amazonaws.com/enchulameelchangarro2024/images/05.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

.logoimg {
    width: 700px;
}

.textoTitulo {
    font-size: 60px;
}

@media (max-width: 576px) {
    .logoimg {
        width: 300px;
    }

    .fondoProximamente {
        width: 100%;
        background-size: cover;
    }

    .textoTitulo {
        font-size: 40px;
    }
}

/**HEADER**/
.navbar {
    background-color: rgba(1, 162, 219, 0.5);
    backdrop-filter: blur(10px);
}

.bordeMenu {
    border-bottom: 1px solid white !important;
}

.nav-link {
    font-family: "StyreneA-Bold";
    text-transform: uppercase;
    color: white;
}

.navbar-toggler-icon {
    background-image: url("https://eenchulame.s3.us-west-2.amazonaws.com/enchulameelchangarro2024/images/bars-solid.svg");
}

.navbar-toggler {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(36deg) brightness(109%) contrast(103%);
    border: none;
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
}

/*Home*/
.logoimgHome {
    width: 500px;
}

.iconosHome {
    width: 35%;
}

.iconosHome1 {
    width: 27%;
}

.textoHome {
    color: white;
}

.btnRegistro {
    font-family: "StyreneA-Bold";
    background: linear-gradient(90deg, rgb(55, 174, 214) 32%, rgb(31, 137, 198) 56%, rgb(0, 100, 131) 78%);
    width: 350px;
    height: 60px;
    border-radius: 97px;
    color: white;
    font-weight: bold;
    font-size: 20px;
}
.sacarID{
    cursor: pointer;
}
/*Premios*/
.tituloPremios {
    font-family: "StyreneA-Light";
    text-transform: uppercase;
    text-align: center;
    font-size: 80px;
    letter-spacing: 10px;
    color: #9E9D9D;
}

.imgBag {
    width: 246px;
}

.premiosText {
    font-family: "StyreneA-Bold";
    font-size: 80px;
    margin: 0;
    padding: 0;
    line-height: 1;
    text-align: end;
}

p.premiosText:nth-child(4) {
    margin: 0;
    padding: 0;
    line-height: 1;
    font-size: 65px;
}

.premiosTextAzul {
    margin: auto;
    padding: 0;
    line-height: 1;
    font-family: "StyreneA-Black";
    font-size: 250px;
    color: #01A2DB;
    text-align: end;
}

.contenedorFrase {
    width: 50%;
}

.fraseText {
    font-family: "StyreneA-light";
    color: #01A2DB;
    text-align: center;
}

/*Jurado*/
.juradoCard{
    min-height: 650px;
}
.fondoComponents {
    background-image: linear-gradient(to right bottom, #01bcfe, #00b8fb, #00b3f9, #00aff6, #00aaf3, #00a1ee, #0097e9, #008ee3, #007ed8, #006dcd, #005dc0, #094cb3);
}

.juradoTitulo {
    margin-top: 10px;
    font-family: "StyreneA-Light";
    text-align: center;
    text-transform: uppercase;
    font-size: 70px;
    letter-spacing: 10px;
    color: white;
}

.juradoText {
    font-family: "StyreneA-Black";
    text-transform: uppercase;
    font-size: 20px;
}

.image-container {
    width: 320px;
    overflow: hidden;
    max-height: 350px;
    margin: auto;
}

.image-container img {
    width: 100%;
    clip-path: inset(0 0 100px 0);
    /* Recortar 50px desde abajo */
}
.dennis {
    width: 100%;
    clip-path: inset(0 0 20px 0) !important;
    /* Recortar 50px desde abajo */
}
.scott {
    width: 100%;
    clip-path: inset(0 0 0px 0) !important;
    /* Recortar 50px desde abajo */
}
.card {
    max-width: 350px;
    --bs-card-border-radius: none !important;
    --bs-card-inner-border-radius: none !important;
}

swiper-container::part(button-next),
swiper-container::part(button-prev) {
    height: 100px;
    width: 60px;
    color: #064FB6;
    padding: 10px;
}

/*Casos*/
.contenedorCardCasos{
    max-width: 470px;
    border: none;
}
.tituloCasos {
    font-family: "StyreneA-Light";
    text-transform: uppercase;
    text-align: center;
    font-size: 60px;
    letter-spacing: 10px;
    color: #9E9D9D;
}
.imgGanadores{
    width: 200px;
}
.img {
    width: 100px;
    height: 200px;
    background-color: black;
}

.contenedorExitos {
    width: 400px;
}

/*Consejos*/
.image-containerPost {
    width: 320px;
    margin: auto;
  }
.ConsejosTitulo {
    margin-top: 10px;
    font-family: "StyreneA-Light";
    text-align: center;
    text-transform: uppercase;
    font-size: 55px;
    letter-spacing: 10px;
    color: white;
}
.contenedorPost{
    min-height: 550px;
    margin-bottom: 20px;
}
.postText{
    font-family: "StyreneA-Black";
  text-transform: uppercase;
  font-size: 15px;
}
.contenedorPost p {
    font-size: 15px;
}
/*Thanks*/
#thanksPage{
    background-image: linear-gradient(to right bottom, #01bcfe, #00b8fb, #00b3f9, #00aff6, #00aaf3, #00a1ee, #0097e9, #008ee3, #007ed8, #006dcd, #005dc0, #094cb3);
}
.thanks{
    width: 50%;
}
/*Footer*/
.iconFooter {
    margin: auto;
    max-width: 200px;
}

.iconFooterMercado {
    margin: auto;
    max-width: 350px;
}

.iconFooterMMK {
    max-width: 200px;
}

.iconFooterW {
    max-width: 100px;
}

.containerFooter {
    margin: auto;
}

.titulosECCH {
    font-family: 'StyreneA-Bold';
    color: white;
    letter-spacing: 2px;
}

.letrasFooter {
    font-family: 'StyreneA-Light';
    color: white;
    font-size: 12px;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    /* Header */

    /* Home */
    .logoimgHome {
        width: 100%;
    }

    .btnRegistro {
        width: 340px;
    }

    /* Premios */
    .tituloPremios {
        font-size: 50px;
    }

    .imgBag {
        width: 150px;
    }

    .premiosText {
        text-align: center;
        font-size: 52px;
    }

    p.premiosText:nth-child(4) {
        font-size: 42px;
    }

    .margenText {
        margin-top: 20px !important;
    }

    .margenImg {
        display: flex;
        justify-content: center;
        margin-top: 30px !important;
    }

    .premiosTextAzul {
        text-align: center;
        font-size: 100px;
    }

    .contenedorFrase {
        width: 100%;
    }

    /* Jurado */
    .juradoTitulo {
        font-size: 50px;
    }

    .image-container {
        width: 300px;
        overflow: hidden;
        max-height: 350px;
        margin: auto;
    }

    .image-container img {
        width: 300px;
        clip-path: inset(0 0 100px 10px 0);
        /* Recortar 50px desde abajo */
    }

    .card {
        max-width: 350px;
        --bs-card-border-radius: none !important;
        --bs-card-inner-border-radius: none !important;
    }

    swiper-container::part(button-next),
    swiper-container::part(button-prev) {
        width: 25px;
        color: #0095e4;
    }

    /* Casos */
    .contenedorCardCasos{
        max-width: 250px;
        text-align: center;
    }
    .imgGanadores{
        width: 150px;
    }
    .tituloCasos {
        font-size: 30px;
    }

    .contenedorExitos {
        width: 330px;
    }

    /* Consejos */
    .image-containerPost {
        width: 300px;
        margin: auto;
    }
    .ConsejosTitulo {
        letter-spacing: 3px;
        font-size: 28px;
    }
    .contenedorPost{
        width: 300px;
        min-height: 470px;
        margin-bottom: 20px;
    }
    .contenedorPost img{
        width: 250px;
        margin: auto;
    }
    .postText{
      font-size: 14px;
    }
    .contenedorPost p {
        font-size: 12px;
    }
    /*Thanks*/
    .thanks{
        width: 100%;
    }
    /* Footer */
    .iconFooter {
        max-width: 100px;
    }

    .iconFooterMercado {
        max-width: 250px;
    }

    .iconFooterW {
        max-width: 70px;
    }

    .iconFooterMMK {
        max-width: 70px;
    }

    .titulosECCH {
        font-size: 15px;
    }

    .letrasFooter {
        font-size: 10px;
    }
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    /* Header */

    /* Home */

    /* Premios */
    .imgBag {
        width: 190px;
    }

    .premiosText {
        font-size: 62px;
    }

    p.premiosText:nth-child(4) {
        font-size: 50px;
    }

    .premiosTextAzul {
        font-size: 200px;
    }

    /* Jurado */
    .ConsejosTitulo {
        font-size: 40px;
    }

    .contenedorExitos {
        width: 370px;
    }

    .image-container {
        width: 200px;
        overflow: hidden;
        max-height: 250px;
        margin: auto;
    }

    .image-container img {
        width: 200px;
        clip-path: inset(0 0 10px 0);
        /* Recortar 50px desde abajo */
    }

    .card {
        max-width: 350px;
        --bs-card-border-radius: none !important;
        --bs-card-inner-border-radius: none !important;
    }
    .juradoCard {
        min-height: 550px;
      }
    /* Casos */
    .contenedorCardCasos{
        max-width: 470px;
        border: none;
    }
    /* Consejos */

    /* Footer */
}
@media only screen and (min-device-width : 768px) and (max-device-width : 10px) and (orientation : landscape) {
    /* Consejos */
    .image-container {
        width: 270px;
    }
}